import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { loyaltyRewardsReducer, loyaltyRewardsSaga } from './ducks/loyalty-rewards';
import { ratings, rootSaga as ratingsSaga, FETCH_RATINGS, RESET as RATINGS_RESET } from './ducks/rating';
import { ADD_TO_BASKET, REMOVE_FROM_BASKET, rootSaga as basketSaga } from './ducks/basket';
import { ADD_TO_SHOPLIST, REMOVE_FROM_SHOPLIST, rootSaga as shoplistSaga } from './ducks/shoplist';
import {
  rootSaga as productsByIdsSaga,
  productsByIds,
  FETCH_PRODUCTS_BY_IDS,
  RESET as PRODUCTS_BY_ID_RESET,
} from './ducks/products-by-ids';

export const products = combineReducers({
  ratings,
  productsByIds,
  loyaltyRewards: loyaltyRewardsReducer,
});

export const productsActions = {
  FETCH_RATINGS,
  RATINGS_RESET,
  ADD_TO_BASKET,
  ADD_TO_SHOPLIST,
  REMOVE_FROM_BASKET,
  REMOVE_FROM_SHOPLIST,
  FETCH_PRODUCTS_BY_IDS,
  PRODUCTS_BY_ID_RESET,
};

export {
  FETCH_RATINGS,
  RATINGS_RESET,
  FETCH_PRODUCTS_BY_IDS,
  ADD_TO_BASKET,
  REMOVE_FROM_BASKET,
  PRODUCTS_BY_ID_RESET,
};

export * from './ducks/selectors';
export * from './components/product';
export * from './types';

export function* productsRootSaga(): unknown {
  return yield all([ratingsSaga(), basketSaga(), shoplistSaga(), productsByIdsSaga(), loyaltyRewardsSaga()]);
}
