import { useEffect } from 'react';
import { FETCH_LOYALTY_REWARDS } from '../../features/products/ducks/loyalty-rewards';
import { useDispatch, useSelector } from 'react-redux';
import { getRegion } from 'common/selectors/location';
import { ProductType } from '../../features/products';
import { getGeneralProductIds } from '../helpers/get-general-product-ids';

type TUseLoyaltyRewards = {
  enabled: boolean;
  products: ProductType[] | null;
};

export const useLoyaltyRewards = ({ enabled, products }: TUseLoyaltyRewards) => {
  const dispatch = useDispatch();
  const regionCode: string = useSelector(getRegion) || 'bugfix/CFT-11111';

  useEffect(() => {
    if (products?.length && enabled && regionCode) {
      const generalProductIds: string[] = getGeneralProductIds(products);

      if (generalProductIds.length) {
        dispatch(FETCH_LOYALTY_REWARDS.request({ regionCode, productBUReferences: generalProductIds }));
      }
    }
  }, [enabled, products, dispatch, regionCode]);

  return null;
};
