import React from 'react';
import { connect } from 'react-redux';
import { ProductCardWithClickEvent } from 'common/components';
import { memo } from 'common/hocs/memo';
import { mapDispatchToProps, mapStateToProps } from './product-maps';
import { ProductLogicProps } from './product.types';
import { useProductLogic } from './use-product-logic';

export const ProductLogic = memo<ProductLogicProps>(function ProductLogic({
  className,
  type = 'large-card',
  elementBlock = 'not set',
  product,
  inBasket,
  qty = 0,
  productPosition,
  addToBasket,
  removeFromBasket,
  syncCartWithCookie,
  reviews,
  updateCartItem,
  inWidget,
  inShoplist,
  addToShoplist,
  removeFromShoplist,
  rrContentId = '',
}) {
  const {
    productRef,
    review,
    label,
    handleAddToCart,
    handleUpdateCart,
    handleRemoveFromCart,
    onClick,
    toggleShoplist,
    handleClickEvent,
  } = useProductLogic({
    elementBlock,
    product,
    qty,
    productPosition,
    addToBasket,
    removeFromBasket,
    syncCartWithCookie,
    reviews,
    updateCartItem,
    rrContentId,
    inShoplist,
    addToShoplist,
    removeFromShoplist,
  });

  return (
    <ProductCardWithClickEvent
      onPointerUpWithoutScroll={handleClickEvent}
      className={className}
      product={product}
      ref={productRef}
      type={type}
      label={label}
      inBasket={inBasket}
      qty={qty}
      onClick={onClick}
      onCartAdd={handleAddToCart}
      onQtyUpdate={handleUpdateCart}
      onCartRemove={handleRemoveFromCart}
      onShoplistToggle={toggleShoplist}
      reviews={review}
      inWidget={inWidget}
      inShoplist={inShoplist}
    />
  );
});

export const Product = connect(mapStateToProps, mapDispatchToProps)(ProductLogic);
