/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/dot-notation */

import { useOnScreen } from './use-on-screen';

type RRTrackingProps = {
  productRef: React.MutableRefObject<HTMLDivElement | null>;
  rrContentId: string | undefined;
};

const viewedContentIds: string[] = [];
const clickedContentIds: string[] = [];

export const clickRRTracking = (impressionContentId: string) => {
  if (clickedContentIds.includes(impressionContentId)) return;

  if (!window['rrApiOnReady']) window['rrApiOnReady'] = [];

  window['rrApiOnReady'].push(function () {
    try {
      window?.rrApi?.impressionContentClicked({
        impressionContentId,
      });
    } catch {
      /* empty */
    }
  });

  clickedContentIds.push(impressionContentId);
};

export const viewRRTracking = (impressionContentId: string) => {
  if (viewedContentIds.includes(impressionContentId)) return;

  if (!window['rrApiOnReady']) window['rrApiOnReady'] = [];

  window['rrApiOnReady'].push(function () {
    try {
      window?.rrApi?.impressionContentViewed({
        impressionContentId,
      });
    } catch {
      /* empty */
    }
  });

  viewedContentIds.push(impressionContentId);
};

export const useRRTracking = ({ productRef, rrContentId }: RRTrackingProps) => {
  const isOnScreen = useOnScreen(productRef, {
    threshold: 0.5,
  });

  const handleClickEvent = (e: PointerEvent) => {
    if (!rrContentId) return;

    if (
      e.ctrlKey ||
      e.shiftKey ||
      e.metaKey || // for apple
      (e.button && e.button === 1) // middle/wheel click
    ) {
      clickRRTracking(rrContentId);
      return;
    }

    // disallow right click
    if (e.button === 2) return;

    clickRRTracking(rrContentId);
  };

  if (isOnScreen && rrContentId) viewRRTracking(rrContentId);

  return {
    handleClickEvent,
  };
};
