import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';
import { ALLOW_COMPARISONS_COOKIE_KEY } from '../constants';

export const getCookies = ({ cookies }: RootState) => cookies?.cookies;
export const getCookiesString = createSelector(getCookies, (cookies) =>
  Object.entries(cookies)
    .map((cookie) => cookie.join('='))
    .join('; '),
);

export const getCustomerID = createSelector(getCookies, (cookies) => cookies?.customerId ?? cookies?._ga);

export const isAllCheck = (...args: boolean[]): boolean => args.every((c) => c);

export const getApiOption = createSelector(getCookies, (cookies) => cookies?.['x-api-option']);

export const getIsAuthorized = createSelector(getCookies, (cookie) => {
  return cookie['user-auth'] === 'true';
});

export const getAllowProductComparisons = createSelector(
  getCookies,
  (cookie) => cookie?.[ALLOW_COMPARISONS_COOKIE_KEY] === '1',
);
