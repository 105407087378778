import React, { FC, useState } from 'react';
import { Compare } from '@navi-toolbox/ui-components';
import { useTranslation } from 'react-i18next';

export const DATA_QA_COMPARE_LIST_ACTION = 'compare-list-action';

type CompareListActionProps = {
  initialActive: boolean;
  hideText?: boolean;
  onClick: () => void;
};

export const CompareListAction: FC<CompareListActionProps> = ({
  initialActive,
  hideText = undefined,
  onClick,
}) => {
  const [isActive, setIsActive] = useState(initialActive);

  const { t } = useTranslation();

  const addedButtonText = t('ui.product-card.compare-list-action-added');
  const notAddedButtonText = t('ui.product-card.compare-list-action-not-added');

  const buttonTextByActive = isActive ? addedButtonText : notAddedButtonText;
  const buttonText = hideText ? undefined : buttonTextByActive;
  const iconSize = hideText ? 'l' : 'm';

  const handleClick = () => {
    const newChecked = !isActive;
    setIsActive(newChecked);
    onClick?.();
  };

  return (
    <Compare
      data-qa={DATA_QA_COMPARE_LIST_ACTION}
      onClick={handleClick}
      active={isActive}
      buttonText={buttonText}
      size={iconSize}
    />
  );
};
