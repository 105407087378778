import { PayloadProps } from './types';

export type ReducerAction =
  | {
      type: typeof REDUX_BASKET_ADD | typeof REDUX_BASKET_REMOVE;
      payload: string;
    }
  | {
      type: typeof REDUX_BASKET_CERTAIN_QUANTITY_ADD | typeof REDUX_BASKET_REMOVE_QUANTITY;
      payload: PayloadProps;
    }
  | {
      type: typeof REDUX_BASKET_SET;
      payload: PayloadProps[];
    };

export const REDUX_BASKET_ADD = Symbol('REDUX_BASKET_ADD');
export const REDUX_BASKET_CERTAIN_QUANTITY_ADD = Symbol('REDUX_BASKET_CERTAIN_QUANTITY_ADD');
export const REDUX_BASKET_REMOVE = Symbol('REDUX_BASKET_REMOVE');
export const REDUX_BASKET_REMOVE_QUANTITY = Symbol('REDUX_BASKET_REMOVE_QUANTITY');
export const REDUX_BASKET_SET = Symbol('REDUX_BASKET_SET');
export const REDUX_BASKET_UPDATED = Symbol('REDUX_BASKET_UPDATED');

export const REDUX_SHOPLIST_ADD = Symbol('REDUX_SHOPLIST_ADD');
export const REDUX_SHOPLIST_REMOVE = Symbol('REDUX_SHOPLIST_REMOVE');
export const REDUX_SHOPLIST_UPDATED = Symbol('REDUX_SHOPLIST_UPDATED');

export function addToBasket(id: string) {
  return { type: REDUX_BASKET_ADD, payload: id };
}

export function addCertainQuantityToBasket(payload: PayloadProps) {
  return { type: REDUX_BASKET_CERTAIN_QUANTITY_ADD, payload };
}

export function removeFromBasket(id: string) {
  return { type: REDUX_BASKET_REMOVE, payload: id };
}

export function removeFromBasketWithQuantity(payload: PayloadProps) {
  return { type: REDUX_BASKET_REMOVE_QUANTITY, payload };
}

export function setBasket(payload: PayloadProps[]) {
  return { type: REDUX_BASKET_SET, payload };
}

export function addToShoplist(id: string) {
  return { type: REDUX_SHOPLIST_ADD, payload: id };
}

export function removeFromShoplist(id: string) {
  return { type: REDUX_SHOPLIST_REMOVE, payload: id };
}
