import { ActionType, createAsyncAction, createReducer, PayloadAction } from 'typesafe-actions';
import { ILoyaltyRewardsRequest, ILoyaltyRewardsResponse, ILoyaltyRewardValue } from '../types';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { fetchLoyaltyRewardsRequest } from './api';
import { combineReducers } from 'redux';

export const FETCH_LOYALTY_REWARDS = createAsyncAction(
  'loyaltyRewards/FETCH_LOYALTY_REWARDS/request',
  'loyaltyRewards/FETCH_LOYALTY_REWARDS/success',
  'loyaltyRewards/FETCH_LOYALTY_REWARDS/failure',
  'loyaltyRewards/FETCH_LOYALTY_REWARDS/cancel',
)<ILoyaltyRewardsRequest, ILoyaltyRewardValue | null, Error, void>();

export type FetchLoyaltyRewardsReducerActions = ActionType<typeof FETCH_LOYALTY_REWARDS.success>;
export const loyaltyRewards = createReducer<ILoyaltyRewardValue | null, FetchLoyaltyRewardsReducerActions>(
  null,
).handleAction(FETCH_LOYALTY_REWARDS.success, (_, { payload }) => payload);

export type FetchLoyaltyRewardsLoadingReducerActions =
  | ActionType<typeof FETCH_LOYALTY_REWARDS.request>
  | ActionType<typeof FETCH_LOYALTY_REWARDS.success>
  | ActionType<typeof FETCH_LOYALTY_REWARDS.failure>
  | ActionType<typeof FETCH_LOYALTY_REWARDS.cancel>;
export const loyaltyRewardsLoading = createReducer<boolean, FetchLoyaltyRewardsLoadingReducerActions>(false)
  .handleAction(FETCH_LOYALTY_REWARDS.request, () => true)
  .handleAction(
    [FETCH_LOYALTY_REWARDS.success, FETCH_LOYALTY_REWARDS.failure, FETCH_LOYALTY_REWARDS.cancel],
    () => false,
  );

export type FetchLoyaltyRewardsErrorReducerActions =
  | ActionType<typeof FETCH_LOYALTY_REWARDS.request>
  | ActionType<typeof FETCH_LOYALTY_REWARDS.failure>;
export const loyaltyRewardsError = createReducer<string | null, FetchLoyaltyRewardsErrorReducerActions>(null)
  .handleAction(FETCH_LOYALTY_REWARDS.request, () => null)
  .handleAction(FETCH_LOYALTY_REWARDS.failure, (_, { payload }) => payload.message);

export const loyaltyRewardsReducer = combineReducers({
  data: loyaltyRewards,
  error: loyaltyRewardsError,
  loading: loyaltyRewardsLoading,
});

export function* fetchLoyaltyRewardsEffect({
  payload: { regionCode, productBUReferences },
}: PayloadAction<string, { regionCode: string; productBUReferences: string[] }>) {
  const loyaltyRewardsBody: ILoyaltyRewardsRequest = {
    regionCode,
    productBUReferences,
  };
  try {
    const result: ILoyaltyRewardsResponse = yield call(fetchLoyaltyRewardsRequest, loyaltyRewardsBody);

    if (result?.loyaltyRewards.length) {
      const mapLoyaltyRewards: ILoyaltyRewardValue = result.loyaltyRewards.reduce((acc, reward) => {
        if (!acc[reward.productBUReference]) {
          acc[reward.productBUReference] = {
            label: reward.label,
          };
        }

        return acc;
      }, {} as ILoyaltyRewardValue);

      yield put(FETCH_LOYALTY_REWARDS.success(mapLoyaltyRewards));
    }
  } catch (err) {
    const error = err as Error;
    yield put(FETCH_LOYALTY_REWARDS.failure(error));
  }
}

export function* loyaltyRewardsSaga() {
  yield all([takeLatest(FETCH_LOYALTY_REWARDS.request, fetchLoyaltyRewardsEffect)]);
}
