import { post, put } from 'features/api';
import { HttpCancelToken, HttpRequestConfig } from '@okapi/http';
import { ApiCancelToken } from 'api';
import {
  FetchProductsResponse,
  FetchRatingsResponse,
  ILoyaltyRewardsRequest,
  ILoyaltyRewardsResponse,
  SetBasketParams,
} from '../types';
import { call } from 'redux-saga/effects';

export const fetchRatings = (
  props: { productsIds: string[] },
  cancelToken?: ApiCancelToken,
  regionCode?: string,
) =>
  post<FetchRatingsResponse>(
    'getRatings',
    props,
    { cancelToken, timeout: 300 } as HttpRequestConfig,
    regionCode ? { regionCode } : null,
  );

export const fetchProductsByIDs = (
  props: { regionCode: string; productsIds: string[] },
  cancelToken?: ApiCancelToken,
) => post<Pick<FetchProductsResponse, 'content'>>('getProductsByIds', props, { cancelToken });

export const fetchLoyaltyRewardsRequest = (params: ILoyaltyRewardsRequest) =>
  post<ILoyaltyRewardsResponse>('post-loyalty-rewards', params, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export function* setBasket(
  { products, regionCode }: SetBasketParams,
  cancelToken: HttpCancelToken,
): Generator {
  return yield call(
    put,
    'set-basket',
    {
      products,
      regionCode,
    },
    {
      cancelToken,
    },
  );
}
