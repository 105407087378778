export function getDomain(hostname = '') {
  const host = hostname.replace(/:[0-9]+/, '');

  if (host) {
    const localhost = 'localhost';
    const separator = '.';
    const start = host.indexOf(separator);

    const isIpAddress =
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g.test(
        host,
      );

    if (isIpAddress) {
      return host;
    }

    if (host.endsWith(localhost)) {
      const splitted = host.split('.');

      return splitted[splitted.length - 1];
    }

    if (start !== -1) {
      const split = host.split(separator);

      return host
        .split(separator)
        .slice(split.length - 2)
        .join(separator);
    }
  }

  return host;
}
