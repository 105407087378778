import React from 'react';
import { WishlistCheckbox } from '@navi-toolbox/ui-components';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from 'common/hooks';

export type Props = {
  productId: string;
  inShoplist: boolean;
  hasText?: boolean;
  size: 's' | 'm' | 'l';
  onShoplistToggle: (productId: string, flowQty: number) => void;
};

export const WishlistButton: React.FC<Props> = ({
  productId,
  hasText = false,
  size,
  inShoplist,
  onShoplistToggle,
}) => {
  const { t } = useTranslation();

  const breakpoints = useBreakpoints();

  const isDesktop = breakpoints?.largeDesktop || breakpoints?.desktop;

  const text = {
    snackAdd: t('ui.product-card.shopping-list-snack-add'),
    tooltipAdd: t('ui.product-card.shopping-list-tooltip-add'),
    tooltipRemove: t('ui.product-card.shopping-list-tooltip-remove'),
    wishlistAdd: t('ui.product-card.shopping-list-checkbox-label'),
    wishlistAdded: t('ui.product-card.shopping-list-checkbox-label-checked'),
  };

  return (
    <WishlistCheckbox
      productId={productId}
      isDesktop={isDesktop}
      hasText={hasText}
      size={size}
      isInteractive={!!productId}
      isChecked={inShoplist}
      onChange={onShoplistToggle}
      text={text}
    />
  );
};
