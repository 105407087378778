import { call, select, CallEffect, SelectEffect } from 'redux-saga/effects';
import { getApiKey, getApiOption, getEnv, getOrchestratorHost } from 'common/selectors';
import { getLang } from 'utils';
import { api, ApiRequestConfig } from '../api';
import { KV } from '../types/utils';

export function* post<Response, Data extends Record<string, unknown> = NonNullable<unknown>>(
  path: string,
  data: Data,
  config?: ApiRequestConfig,
  queryParams?: Record<string, string> | null,
): Generator<unknown, Response, Response> {
  const params = queryParams ? new URLSearchParams(queryParams || '').toString() : '';
  const resultPath = params ? `${path}?${params}` : path;
  return yield* doRequest<Response, Data>('post', resultPath, data, config);
}

export function* put<Response, Data extends object>(
  path: string,
  data: Data,
  config?: ApiRequestConfig,
): Generator<unknown, Response, Response> {
  return yield* doRequest<Response, Data>('put', path, data, config);
}

export function* get<Response, Data extends object>(
  path: string,
  data: Data,
  config: ApiRequestConfig = {},
) {
  return yield* doRequest<Response, Data>('get', path, data, config);
}

function* doRequest<Response, Data>(
  method: 'get' | 'post' | 'put',
  path: string,
  data: Data,
  config: ApiRequestConfig = {},
): Generator<CallEffect<unknown> | SelectEffect, Response> {
  const { USE_CREDENTIALS, requestID } = (yield select(getEnv)) as ReturnType<typeof getEnv>;
  const orchestratorHost = (yield select(getOrchestratorHost)) as ReturnType<typeof getOrchestratorHost>;
  const apiKey = (yield select(getApiKey)) as ReturnType<typeof getApiKey>;
  const apiOption = (yield select(getApiOption)) as ReturnType<typeof getApiOption>;
  const lang = yield call(getLang);

  config.withCredentials = USE_CREDENTIALS;

  if (requestID) {
    config.headers = { ...config.headers, 'x-request-id': requestID };
  }

  if (apiKey) {
    config.headers = { ...config.headers, 'x-api-key': apiKey };
  }

  if (apiOption) {
    config.headers = { ...config.headers, 'x-api-option': apiOption };
  }

  const apiMethod = api[method];
  const url = buildUrl(orchestratorHost || '', path, { lang });

  return yield call(apiMethod, url, data, config);
}

export function buildUrl(base: string, part: string, params?: KV) {
  const searchParams = new URLSearchParams(params || {});
  const resultUrl = new URL(part, base);

  searchParams.forEach((value, key) => resultUrl.searchParams.set(key, value));

  return resultUrl.toString();
}
