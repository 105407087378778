import type { RootState } from 'typesafe-actions';
import type { ProductOwnProps } from './product.types';
import { BasketFlowActions, getBasket } from 'features/basket';
import type { Dispatch } from 'redux';
import { getShoplist } from 'features/products/ducks/selectors';
import { ADD_TO_SHOPLIST, REMOVE_FROM_SHOPLIST } from 'features/products/ducks/shoplist';

export const mapStateToProps = (state: RootState, ownProps: ProductOwnProps) => {
  const basket = getBasket(state);
  const { product } = ownProps;
  const cartEntity = basket.find((e: { code: string }) => e.code === product.productId);
  const inBasket = !!cartEntity;
  const qty = cartEntity?.quantity;
  const shopList = getShoplist(state);
  const inShoplist = shopList.includes(product.productId);
  return { inBasket, qty, inShoplist };
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  addToBasket: (code: string, quantity: number) => {
    dispatch(BasketFlowActions.UPDATE_ITEM({ quantity, code }));
  },
  addToShoplist: (id: string) => dispatch(ADD_TO_SHOPLIST(id)),
  updateCartItem: (code: string, quantity: number) =>
    dispatch(BasketFlowActions.UPDATE_ITEM({ quantity, code })),
  syncCartWithCookie: () => dispatch(BasketFlowActions.SYNC_CART_WITH_COOKIE()),
  removeFromBasket: (code: string) => {
    dispatch(BasketFlowActions.RESET_ITEM({ code }));
  },
  removeFromShoplist: (id: string) => dispatch(REMOVE_FROM_SHOPLIST(id)),
});
