import { createAction } from 'typesafe-actions';
import { put, takeEvery, all } from 'redux-saga/effects';
import { addToShoplist, removeFromShoplist } from 'store/header-middleware/actions';
import type { Action } from 'redux';

export const ADD_TO_SHOPLIST = createAction('products/ADD_TO_SHOPLIST')<string>();
export const REMOVE_FROM_SHOPLIST = createAction('products/REMOVE_FROM_SHOPLIST')<string>();

export function* rootSaga() {
  yield all([
    takeEvery(ADD_TO_SHOPLIST, function* handle({ payload }) {
      yield put(addToShoplist(payload) as Action);
    }),
    takeEvery(REMOVE_FROM_SHOPLIST, function* handle({ payload }) {
      yield put(removeFromShoplist(payload) as Action);
    }),
  ]);
}
