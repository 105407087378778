import { REDUX_BASKET_UPDATED, REDUX_SHOPLIST_UPDATED } from './actions';
import { PayloadProps, Layers } from './types';

export function createReducer(initialState: Layers) {
  return function reducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state = initialState,
    action: {
      type: typeof REDUX_BASKET_UPDATED | typeof REDUX_SHOPLIST_UPDATED;
      payload: PayloadProps[];
    },
  ) {
    switch (action.type) {
      case REDUX_BASKET_UPDATED:
        return {
          ...state,
          basket: action.payload.map((item) => item.code),
          basketWithQuantity: action.payload,
        };
      case REDUX_SHOPLIST_UPDATED:
        return {
          ...state,
          shoppingList: action.payload.map((item) => item.code),
        };
      default:
        return state;
    }
  };
}
