import { useIntersectionObserverCallback, useRRTracking } from 'common/hooks';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { analyticsUtils } from 'utils/analytics-utils';
import { isOnlineOnly, labelsGaNames } from './utils';
import type { UseProductLogicProps } from './product.types';
import type { ProductPriceCategory } from 'features/products/types';

const EVENT = 'basket:update';

export const useProductLogic = ({
  elementBlock = 'not set',
  product,
  qty = 0,
  productPosition,
  addToBasket,
  removeFromBasket,
  syncCartWithCookie,
  reviews,
  updateCartItem,
  rrContentId = '',
  inShoplist,
  addToShoplist,
  removeFromShoplist,
}: UseProductLogicProps) => {
  const productRef = useRef<HTMLDivElement | null>(null);
  const prevQtyRef = useRef<number>(qty);

  const { handleClickEvent } = useRRTracking({ productRef, rrContentId });

  useEffect(() => {
    prevQtyRef.current = qty;
  }, [qty]);

  const label: ProductPriceCategory | 'PIM' = useMemo(
    () => (isOnlineOnly(product.source) ? 'PIM' : product.productPriceCategory),
    [product.productPriceCategory, product.source],
  );
  const gaTag = useMemo(() => {
    if (product.productPriceCategory === 'STD' && !product.price.previous_price) {
      if (isOnlineOnly(product.source)) {
        return labelsGaNames.PIM;
      }
      return '';
    }
    if (
      product.productPriceCategory &&
      product.productPriceCategory.toLowerCase() !== 'pim' &&
      isOnlineOnly(product.source)
    ) {
      return `${labelsGaNames[product.productPriceCategory]},onlineOnly`;
    }
    return labelsGaNames[product.productPriceCategory];
  }, [product.productPriceCategory, product.price.previous_price, product.source]);

  const review = useMemo(
    () => reviews?.find((item) => item.productId?.toString() === product.productId) ?? null,
    [product.productId, reviews],
  );

  useEffect(() => {
    const cb = () => {
      syncCartWithCookie();
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !__IS_SERVER__ && document.body.addEventListener(EVENT, cb, { passive: true });

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !__IS_SERVER__ && document.body.removeEventListener(EVENT, cb);
    };
  }, [syncCartWithCookie]);

  const commonGaData = useMemo(
    () => ({
      elementBlock,
      elementType: 'product',
      productName: product.displayedName,
      productId: product.productId,
      productPrice: product.price.main_price,
      productPosition,
      productPrevPrice: product.price.previous_price ?? null,
      productDiscount: product.price.discount_percent ?? null,
      productTag: gaTag,
    }),
    [elementBlock, product, productPosition, gaTag],
  );

  const handleAddToCart = useCallback(() => {
    const step = product.price.step || 1;
    let initialSteps = Math.round(1 / step);

    if (step * initialSteps !== 1) initialSteps += 1;
    if (step > 1) initialSteps = 1;

    addToBasket(product.productId, step * initialSteps);
    prevQtyRef.current = step * initialSteps;

    analyticsUtils.sendEvent('GA:addToCart', {
      productQuantity: parseFloat((step * initialSteps).toFixed(2)).toString(),
      ...commonGaData,
    });
  }, [addToBasket, commonGaData, product]);

  const handleUpdateCart = useCallback(
    (code: string, quantity: number) => {
      updateCartItem(code, quantity);

      analyticsUtils.sendEvent(
        quantity >= (prevQtyRef?.current || 0) ? 'GA:addToCart' : 'GA:removeFromCart',
        {
          productQuantity: parseFloat(Math.abs(quantity - prevQtyRef.current).toFixed(2)).toString(),
          ...commonGaData,
        },
      );

      prevQtyRef.current = quantity;
    },
    [commonGaData, updateCartItem],
  );

  const handleRemoveFromCart = useCallback(() => {
    removeFromBasket(product.productId);
  }, [product, removeFromBasket]);

  useIntersectionObserverCallback(
    productRef,
    {
      enter: () => {
        analyticsUtils.sendEvent('GA:impressions', {
          ...commonGaData,
        });
      },
      extraUnobserve: 'enter',
      options: { rootMargin: '-30px' },
    },
    [productPosition],
  );

  const toggleShoplist = useCallback(() => {
    if (inShoplist) removeFromShoplist(product.productId);
    else addToShoplist(product.productId);

    analyticsUtils.sendEvent(inShoplist ? 'GA:removeFromShoppingList' : 'GA:addToShoppingList', {
      ...commonGaData,
      elementType: 'button',
    });
  }, [addToShoplist, removeFromShoplist, commonGaData, inShoplist, product.productId]);

  const onClick = useCallback(
    (elementName: 'productNameLink' | 'productPhotoLink' | 'productRatingLink') => {
      analyticsUtils.sendEvent('GA:clickProduct', {
        elementName,
        ...commonGaData,
      });
    },
    [commonGaData],
  );

  return {
    productRef,
    label,
    review,
    handleAddToCart,
    handleUpdateCart,
    handleRemoveFromCart,
    toggleShoplist,
    onClick,
    handleClickEvent,
  };
};
