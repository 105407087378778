import React, { useMemo } from 'react';
import { Typography } from 'fronton-react';
import { cx } from 'linaria';
import {
  oldPriceCrossed,
  container,
  oldCurrencyWrap,
  padLeft,
  padRight,
  primaryPriceFractCurrencyWrap,
  newOldPriceStyle,
  mainPriceWrap,
  specialPriceContainer,
  bestPriceStyle,
  specialOldPriceWrap,
  mainLargePriceWrap,
  smallPriceWrap,
  primaryLargePriceFractCurrencyWrap,
  oldSmallPriceAndCurrencyWrap,
  oldPriceWrapper,
  oldPriceWrapperSmall,
} from './styles';
import { BestPriceTag, DiscountPercentTag } from '@navi-toolbox/ui-components';
import { useTranslation } from 'react-i18next';

export const DATA_QA_DISCOUNT_PERCENT_PLATE = 'discountPercentPlate';
export const DATA_QA_PRODUCT_BEST_PRICE_NAMEPLATE = 'product-best-price-nameplate';

type ProductOldNewPriceProps = {
  hintShown?: boolean;
  currency: string;
  priceFirst: string;
  priceSecond?: string | null;
  rawOldPrice: string;
  hasOldPrice: boolean;
  hasBestPrice: boolean;
  uom?: string;
  isSmallFontSize?: boolean;
  discountPercent?: number;
};

export const ProductSpecialPrice: React.FC<ProductOldNewPriceProps> = ({
  uom,
  priceFirst,
  priceSecond,
  rawOldPrice,
  currency,
  hasOldPrice,
  hasBestPrice,
  isSmallFontSize = false,
  discountPercent,
}) => {
  const oldPrice = useMemo(() => {
    return rawOldPrice.slice(0, rawOldPrice.indexOf('₽'));
  }, [rawOldPrice]);

  const formattedOldPriceString = useMemo(
    () => (oldPrice.slice(-4) === ',00\u00A0' ? `${oldPrice.slice(0, -4)}` : oldPrice),
    [oldPrice],
  );

  const { t } = useTranslation();

  const priceFirstWithComma = `${priceFirst},`;
  const specialPriceColor = hasBestPrice ? bestPriceStyle : newOldPriceStyle;

  return (
    <div data-qa={hasBestPrice ? 'product-best-price' : 'product-old-new-price'} className={container}>
      {hasBestPrice && (
        <BestPriceTag text={t('product-card.best-price')} data-qa={DATA_QA_PRODUCT_BEST_PRICE_NAMEPLATE} />
      )}
      {hasOldPrice && (
        <div className={cx(oldPriceWrapper, isSmallFontSize && oldPriceWrapperSmall)}>
          {!hasBestPrice && discountPercent && (
            <DiscountPercentTag percent={discountPercent} data-qa={DATA_QA_DISCOUNT_PERCENT_PLATE} />
          )}
          <div className={oldPriceCrossed}>
            <Typography
              variant="s"
              size="body_accent"
              className={cx(isSmallFontSize && oldSmallPriceAndCurrencyWrap)}
            >
              {formattedOldPriceString}
            </Typography>
            <Typography
              variant="s"
              size="body_accent"
              className={cx(oldCurrencyWrap, isSmallFontSize && oldSmallPriceAndCurrencyWrap)}
            >
              {currency}
            </Typography>
          </div>
        </div>
      )}

      <div className={specialOldPriceWrap}>
        <div className={specialPriceContainer}>
          <span
            className={cx(
              mainPriceWrap,
              isSmallFontSize ? smallPriceWrap : mainLargePriceWrap,
              specialPriceColor,
            )}
            data-qa={hasBestPrice ? 'best-price-main' : 'new-price-main'}
          >
            {priceSecond ? priceFirstWithComma : priceFirst}
          </span>
          {priceSecond && (
            <span
              className={cx(
                primaryPriceFractCurrencyWrap,
                isSmallFontSize ? smallPriceWrap : primaryLargePriceFractCurrencyWrap,
                specialPriceColor,
                padLeft,
              )}
              data-qa={hasBestPrice ? 'best-price-fract' : 'new-price-fract'}
            >
              {priceSecond}
            </span>
          )}
          <span
            className={cx(
              primaryPriceFractCurrencyWrap,
              isSmallFontSize ? smallPriceWrap : primaryLargePriceFractCurrencyWrap,
              specialPriceColor,
              padRight,
              padLeft,
            )}
          >
            {`${currency}${uom ? `/${uom}` : ''}`}
          </span>
        </div>
      </div>
    </div>
  );
};
