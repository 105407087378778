import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'typesafe-actions';
import { CssBaseline } from 'common/components';
import { ProductsCarousel, ProductsCarouselProps } from 'features/products/components/products-carousel';
import { ThemeProvider } from 'fronton-react';

export type CarouselEntryWidgetProps = { store: Store } & ProductsCarouselProps;

export const CarouselEntryWidget: React.FC<CarouselEntryWidgetProps> = ({ store, ...props }) => {
  return (
    <Provider store={store}>
      <ProductsCarouselWrapper {...props} />
    </Provider>
  );
};

export const ProductsCarouselWrapper = (props: ProductsCarouselProps) => {
  return (
    <ThemeProvider>
      <CssBaseline />
      <React.Suspense fallback={<div>Loading...</div>}>
        <ProductsCarousel {...props} />
      </React.Suspense>
    </ThemeProvider>
  );
};
